<template>
  <nav class="navbar navbar-expand navbar-dark bg-dark position-relative px-3">
    <NuxtLink
      to="/"
      class="navbar-brand"
      :class="{'d-block mx-auto w-100 text-center': !currentUser}"
    >
      Mosaic LTS
    </NuxtLink>

    <div
      v-if="currentUser"
      class="collapse navbar-collapse"
    >
      <div class="navbar-nav">
        <div class="nav-item ms-2">
          <NuxtLink
            to="/"
            class="nav-link"
            :class="{'active': $route.name === 'index'}"
          >
            Map
          </NuxtLink>
        </div>

        <div class="nav-item ms-2">
          <NuxtLink
            to="/trade/new"
            class="nav-link"
            :class="{'active': $route.name === 'trade-new'}"
          >
            Create
          </NuxtLink>
        </div>

        <div class="nav-item ms-2">
          <NuxtLink
            to="/import"
            class="nav-link"
            :class="{'active': $route.name === 'import' || $route.name === 'import-id'}"
          >
            Import
          </NuxtLink>
        </div>

        <div class="nav-item ms-2">
          <NuxtLink
            to="/login"
            class="nav-link"
            @click.prevent="logoutClick"
          >
            Logout
          </NuxtLink>
        </div>
      </div>
    </div>

    <div
      v-if="currentUser"
      class="collapse navbar-collapse justify-content-end"
    >
      <div class="navbar-nav">
        <div class="nav-item text-secondary">
          <div
            class="nav-link p-0 cursor-pointer"
            @click="toastStore.togglePanel()"
          >
            <i class="bi bi-bell-fill" />
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import {useToastStore} from "~/store/toast";

const toastStore = useToastStore();
const currentUser = useAuthUser();
const config = useRuntimeConfig();

const { logout } = useAuth();

async function logoutClick() {
  await logout();
  await navigateTo({ name: "login" });
}
</script>
